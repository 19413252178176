@font-face {
    font-family: "Oxanium";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Oxanium-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Oxanium";
    font-weight: 500;
    font-display: swap;
    src: url("./fonts/Oxanium-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Oxanium";
    font-weight: 600;
    font-display: swap;
    src: url("./fonts/Oxanium-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Oxanium";
    font-weight: 900;
    font-display: swap;
    src: url("./fonts/Oxanium-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Inter', sans-serif;
    font-weight: 900;
    font-display: swap;
    src: url("./fonts/Inter-Bold.ttf") format("truetype");
}
